var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',{staticClass:"justify-space-between mb-0 py-7"},[_c('span',{staticClass:"text-h5"},[_c('strong',[_vm._v("Bill Of Lading")])])]),_c('v-row',{staticClass:"ml-2 mr-2 mb-3"},[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-text-field',{attrs:{"label":"Donor Name","outlined":"","dense":"","hide-details":""},model:{value:(_vm.filter.donor_name),callback:function ($$v) {_vm.$set(_vm.filter, "donor_name", $$v)},expression:"filter.donor_name"}})],1),_c('v-col',{staticClass:"pl-0",attrs:{"cols":"12","md":"3"}},[_c('v-text-field',{attrs:{"label":"Invoice Number","outlined":"","dense":"","hide-details":""},model:{value:(_vm.filter.invoice_no),callback:function ($$v) {_vm.$set(_vm.filter, "invoice_no", $$v)},expression:"filter.invoice_no"}})],1),_c('v-col',{staticClass:"pl-0",attrs:{"cols":"12","md":"3"}},[_c('v-text-field',{attrs:{"label":"Tissue Number","outlined":"","dense":"","hide-details":""},model:{value:(_vm.filter.tissue_no),callback:function ($$v) {_vm.$set(_vm.filter, "tissue_no", $$v)},expression:"filter.tissue_no"}})],1),_c('v-col',{staticClass:"pl-0 text-right",attrs:{"cols":"12","md":"3"}},[_c('v-btn',{staticClass:"mr-2",attrs:{"color":"primary"},on:{"click":function($event){return _vm.BillOFladingSearchList()}}},[_vm._v(" Search")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.getBilloflading()}}},[_vm._v("Reset")])],1)],1),_c('v-data-table',{staticClass:"table-rounded",attrs:{"headers":_vm.headers,"items":_vm.GetLadingBill,"items-per-page":10,"fixed-header":"","height":"400","item-key":"invoice_no","single-expand":_vm.singleExpand,"expanded":_vm.expanded,"show-expand":""},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
var item = ref.item;
return [_c('pre',[_vm._v(_vm._s(_vm.moment.utc(item.date).format("DD-MM-YYYY HH:mm")))])]}},{key:"item.operate",fn:function(ref){
var item = ref.item;
return [(item.button_status == 1)?_c('v-btn',{attrs:{"color":"primary","href":("https://api.ebsr.in/" + (item.pdf_path)),"target":"_blank"}},[_vm._v(" Print ")]):_vm._e(),(item.button_status == 0 && item.status == 1)?_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.BillCancel(item)}}},[_vm._v(" Cancel ")]):_vm._e(),(item.button_status == 0 && item.status == 0)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"light":"","text":""}},'v-btn',attrs,false),on),[_vm._v(" Canceled ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.remark))])]):_vm._e()]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-row',{staticClass:"pt-3 col-12"},[_c('v-col',{attrs:{"sm":"6","md":"6"}},[_c('p',[_c('strong',[_vm._v(" Surgery information ")])]),_c('p',[_vm._v("Bill To: "+_vm._s(item.bill_to))]),_c('p',[_vm._v("Surgery Date-Time: "+_vm._s(item.surgery_date_time))]),_c('p',[_vm._v("Reimbursement Amount: "+_vm._s(item.reimbursement_amount))])])],1)],1)]}}],null,true)}),_c('v-dialog',{attrs:{"max-width":"550"},model:{value:(_vm.DeleteModal),callback:function ($$v) {_vm.DeleteModal=$$v},expression:"DeleteModal"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v(" Cancel confirmation ")]),_c('v-card-text',[_vm._v(" Are you sure you want to Cancel ? ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.DeleteModal = false}}},[_vm._v(" Close ")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.BillCancelitem(_vm.thisItemId)}}},[_vm._v(" Confirm ")])],1)],1)],1),_c('v-snackbar',{attrs:{"timeout":_vm.timeout},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"blue","text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.snackbarText)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }