<template>
  <v-card>
    <v-card-title class="justify-space-between mb-0 py-7">
      <span class="text-h5"><strong>Bill Of Lading</strong></span>
    </v-card-title>
    <!-- <v-card-title class="justify-center">
      <span class="text-h5"><strong> Bill Of Lading</strong></span>
    </v-card-title> -->
    <v-row class="ml-2 mr-2 mb-3">
      <v-col cols="12" md="3">
        <v-text-field v-model="filter.donor_name" label="Donor Name" outlined dense hide-details></v-text-field>
      </v-col>
      <v-col cols="12" md="3" class="pl-0">
        <v-text-field v-model="filter.invoice_no" label="Invoice Number" outlined dense hide-details></v-text-field>
      </v-col>
      <v-col cols="12" md="3" class="pl-0">
        <v-text-field label="Tissue Number" v-model="filter.tissue_no" outlined dense hide-details></v-text-field>
      </v-col>
      <v-col cols="12" md="3" class="pl-0 text-right">
        <v-btn color="primary" class="mr-2" @click="BillOFladingSearchList()"> Search</v-btn>
        <v-btn color="primary"  @click="getBilloflading()">Reset</v-btn>
      </v-col>
      <!-- <v-col cols="12" md="6">

        <v-btn color="primary" class="mr-8" @click="addUserModal = true"> Add User</v-btn>
      </v-col> -->
    </v-row>

    <v-data-table :headers="headers" :items="GetLadingBill" :items-per-page="10" fixed-header height="400"
      item-key='invoice_no' :single-expand="singleExpand" :expanded.sync="expanded" show-expand class="table-rounded">

      <template #[`item.date`]="{ item }">
        <pre>{{moment.utc(item.date).format("DD-MM-YYYY HH:mm")}}</pre>
      </template>
      <template #[`item.operate`]="{ item }">
        <v-btn color="primary" v-if="item.button_status == 1" :href="`https://api.ebsr.in/${item.pdf_path}`" target="_blank"> 
          Print 
        </v-btn>
        <v-btn color="primary" v-if="item.button_status == 0 && item.status == 1" @click="BillCancel(item)">
          Cancel 
        </v-btn>
        <v-tooltip v-if="item.button_status == 0 && item.status == 0" top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn light text v-bind="attrs"
              v-on="on"> Canceled
            </v-btn>
          </template>
          <span>{{item.remark}}</span>
        </v-tooltip>
      </template>

      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <v-row class="pt-3 col-12">
            <v-col sm="6" md="6">
              <p><strong> Surgery information </strong></p>
              <p>Bill To: {{ item.bill_to }}</p>
              <p>Surgery Date-Time: {{ item.surgery_date_time }}</p>
              <p>Reimbursement Amount: {{ item.reimbursement_amount }}</p>
            </v-col>
          </v-row>
        </td>
      </template>   
    </v-data-table>

    <v-dialog v-model="DeleteModal" max-width="550">
      <v-card>
        <v-card-title class="text-h5"> Cancel confirmation </v-card-title>
        <v-card-text> Are you sure you want to Cancel ? </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="DeleteModal = false"> Close </v-btn>
          <v-btn color="primary" @click="BillCancelitem(thisItemId)"> Confirm </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar" :timeout="timeout">
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false"> Close </v-btn>
      </template>
    </v-snackbar>

  </v-card>
</template>

<script>
import BillOfLadingservice from '@/service/LadBilling.service'
import moment from 'moment'
import { api } from '@/config/config'
const localStoragedata = JSON.parse(localStorage.getItem("token"))
const token = localStoragedata.token
export default {


  data() {
    return {
      moment,
      DeleteModal: false,
      thisItemId: '',
      btn: false,
      btn1: false,
      expanded: [],
      singleExpand: true,
      Donor_name: '',
      Tissue_no: '',
      listLoading: false,
      totallist: 0,
      snackbar: false,
      snackbarText: '',
      timeout: 2000,
      GetLadingBill: [],
      filter: {
        donor_name: '',
        invoice_no: '',
        tissue_no: '',
      },

      headers: [
        { text: 'EBSR Number', value: 'EBSR_number', width: '150px' },
        { text: 'Date', value: 'date', width: '120px' },
        { text: 'Donor Name', value: 'donor_name', width: '140px' },
        { text: 'Invoice Number', value: 'invoice_no', width: '140px' },
        { text: 'Tissue Number', value: 'tissue_no', width: '140px' },
        //  { text: 'Bill To', value: 'bill_to', width: '100px' },
        //  { text: 'Reimbursement Amount', value: 'reimbursement_amount', width: '150px' },
        { text: 'Action', value: 'operate', width: '150px' },
      ],
    }
  },
  watch: {
    options: {
      handler() {
        this.getBilloflading()
      },
      deep: true,
    },
  },

  //   computed: {
  //     formIsValid () {
  //       return (
  //         this.AddSystemUser.name &&
  //         this.AddSystemUser.email.match(validRegex) &&
  //         this.AddSystemUser.phone_number &&
  //         this.AddSystemUser.designation &&
  //         this.AddSystemUser.district_name &&
  //         this.AddSystemUser.hospital_name &&
  //         this.AddSystemUser.password
  //       )
  //     },
  //   },

  async mounted() {
    this.getBilloflading()
    this.autoSearchBillOFlading()
  },


  methods: {
    
    async getBilloflading() {
      this.listLoading = true
      const service = new BillOfLadingservice()
      let response = await service.GetLadingBill()
      //   console.log("Hello Bill1",response)
      if (response) {
        this.GetLadingBill = response.data
        // console.log("Hello Bill2",this.GetLadingBill)
        this.totallist = this.GetLadingBill.length
        this.filter.donor_name = '',
          this.filter.invoice_no = '',
          this.filter.tissue_no = ''

      } else {
        this.GetLadingBill = []
        this.totallist = 0
      }
    },


    BillCancel(item) {
      this.DeleteModal = true
      this.thisItemId = item.id
    },

    async BillOFladingSearchList() {
      this.listLoading = true
      const data = {
        donor_name: this.filter.donor_name,
        invoice_no: this.filter.invoice_no,
        tissue_no: this.filter.tissue_no,
      }

      if (this.filter.donor_name == '' && this.filter.invoice_no == '' && this.filter.tissue_no == '') {
        this.snackbarText = 'Kindly Enter The Keyword',
          this.snackbar = true
        return;
      }

      try {
        const response = await api.post(`ladingBill/search`, data, {
          headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        })
        if (response) {

          this.GetLadingBill = response.data.data
          console.log("this.GetLadingBill.length", this.GetLadingBill.length);
          this.totallist = this.GetLadingBill.length
        } else {
          this.GetLadingBill = []
          this.totallist = 0
        }
        this.listLoading = false
      } catch (e) {
        console.log(e)
      }
    },

    async autoSearchBillOFlading() {
      const tissue_id = this.$route.params.tissue_id
      if (tissue_id) {
        this.listLoading = true
        const data = {
          // donor_name: this.filter.donor_name,
          // invoice_no: this.filter.invoice_no,
          tissue_no: tissue_id,
        }
        try {
          const response = await api.post(`ladingBill/search`, data, {
            headers: {
              'Content-type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
          })
          if (response) {
            this.GetLadingBill = response.data.data
            this.totallist = this.GetLadingBill.length
          } else {
            this.GetLadingBill = []
            this.totallist = 0
          }
          this.listLoading = false
        } catch (e) {
          console.log(e)
        }
      }
      return;
    },

    async BillCancelitem(item) {
      try {
        const response = await api.get(`ladingBill/cancel/${item}`, {
          headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${token}`
          },
        })
        if (response.data.status == 200) {
          this.snackbarText = response.data.message
          this.getBilloflading()
          this.DeleteModal = false
        } else {
          this.snackbarText = response.data.message
        }
        this.snackbar = true
      } catch (e) {
        console.log(e)
      }
    },



  },


}
</script>

